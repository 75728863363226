import React, {Fragment} from 'react';

import "./FormCards.scss"
import Card from "../components/Card";

const price = [0, 11.9, 21.8, 29.50, 37.2, 44.9, 52.6, 60.3, 68, 75.7, 83.4, 91.1, 98.8, 106.5];
const priceAdd = [11.9, 9.9, 7.7, 7.7, 7.7, 7.7, 7.7, 7.7, 7.7, 7.7, 7.7, 7.7, 7.7, 7.7, 7.7, 7.7, 7.7, 7.7];

const price_reuse = [11.9,23.8,33.7,41.4,49.1,56.8,64.5,72.2,79.9,87.6,95.3,103,110.7,118.4,126.1];


class FormCards extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: "",
            activeSet: 0,
            hideCards: false,
            explain: true,
            explainStep: 1,
            confirmationOpen: false,
            confirmationOpenIdx: 0,
            cards: [[
                {value: ""},
                {value: ""},
                {value: ""},
                {value: ""},
                {value: ""},
                {value: ""},
                {value: ""},
                {value: ""},
                {value: ""},
                {value: ""}
            ]]
        };

        this.addSet = this.addSet.bind(this);
        this.handleCardChange = this.handleCardChange.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);

        if (window.Cards !== undefined){
            this.state.cards = window.Cards;
            this.state.name = window.Name;
            this.state.explain = window.Explain;
            this.setState({
                hideCards: true,
            });
            this.handleSetSwitch(0);
        }
    }

    componentWillUnmount() {
        window.Cards = this.state.cards;
        window.Name = this.state.name;
        window.Explain = this.state.explain
    }

    handleNameChange(name) {
        this.setState({name: name});
    }

    handleCardChange(key, val){
        this.state.cards[this.state.activeSet][key].value = val;
        //console.log(this.state.cards);
    }

    addSet(){
        if(this.state.cards.length < 9){
            let newCards = this.state.cards;
            newCards.push([
                {value: ""},
                {value: ""},
                {value: ""},
                {value: ""},
                {value: ""},
                {value: ""},
                {value: ""},
                {value: ""},
                {value: ""},
                {value: ""}
            ]);
            this.state.cards = newCards;

            this.setState({
                activeSet: this.state.cards.length-1,
                hideCards: true,
            });
            setTimeout(
                () => this.setState({hideCards: false}),
                200
            )
        }
    }

    handleSetSwitch(goTo){
        this.setState({
            hideCards: true,
            activeSet: goTo,
        });
        setTimeout(
            () => this.setState({hideCards: false}),
            200
        )
    }

    deleteSet(idx){
        if(this.state.cards.length > 1){
            let cards = this.state.cards;
            cards.splice(idx, 1);

            let newIdx = idx === this.state.activeSet ? this.state.activeSet - 1 : this.state.activeSet;
            if(newIdx >= this.state.cards.length){
                newIdx = this.state.cards.length - 1;
            }
            if(newIdx < 0){
                newIdx = 0;
            }

            this.setState({
                cards: cards,
                activeSet: newIdx,
                hideCards: idx === this.state.activeSet,
            });
            setTimeout(
                () => this.setState({hideCards: false}),
                200
            )

        }
    }

    calculatePrice(){
        // console.log(this.props.reUse);
        if(this.state.cards.length > 0 && this.props.reUse){
            return price_reuse[this.state.cards.length - 1];
        } else if(this.state.cards.length > 1 && !this.props.reUse) {
            return price[this.state.cards.length - 1];
        } else{
            return 0;
        }
    }

    render(){
        const activeCards = this.state.cards[this.state.activeSet];
        const cards = activeCards.map((item, key) =>
            <Card key={this.state.activeSet + "-" + key}
                  name={this.state.name}
                  handleNameChange={this.handleNameChange}
                  value={item.value}
                  onChange={(val) => this.handleCardChange(key, val)}/>
        )

        const setSwitch = () => {

            let s = [];
            for (let i = 0; i < this.state.cards.length; i++) {

                s.push(
                    <div key={i}
                         className={"form-cards__set-switch__set" + (this.state.activeSet === i ? " form-cards__set-switch__set--active" : "")}>
                        <div className={"form-cards__set-switch__click"}
                             onClick={() => this.handleSetSwitch(i)}>
                            <span>{i+1}</span>
                        </div>
                        <div className={"form-cards__set-switch__set__delete"}
                             onClick={() => this.setState({confirmationOpen: true, confirmationOpenIdx: i})}>

                        </div>
                    </div>
                )
            }
            return s;
        };

        const confirmation = this.state.confirmationOpen ? (
            <div className={"confirmation__popup"}>

                <div className={"confirmation__message"}>
                    Bist du sicher dass du dieses Set löschen willst?
                </div>

                <div className={"buttons"}>
                    <button onClick={() => this.setState({confirmationOpen: false})}>Nein</button>
                    <button onClick={() => {this.deleteSet(this.state.confirmationOpenIdx); this.setState({confirmationOpen: false})}}>Ja</button>
                </div>
            </div>

        ) : "";

        return (
            <Fragment>
                <div className={"form-cards"}>

                    <div className={"form-cards__gird" + (this.state.hideCards ? " form-cards__gird--hide" : " form-cards__gird--show")}>
                        {cards}
                    </div>

                    <div className={"form-cards__set-switch"}>
                        <div className={"form-cards__set-switch__title"}>Kartenset</div>
                        {setSwitch()}
                        <div className={"form-cards__set-switch__add"} onClick={this.addSet}>+</div>
                        <div className={"form-cards__set-switch__addedprice"}>+ CHF {priceAdd[this.state.cards.length - 1].toFixed(2)}</div>
                    </div>

                    <div className={"form-cards__price"}>
                        <small>Total</small>
                        CHF {this.calculatePrice().toFixed(2)}
                    </div>

                </div>
                <div className={"form-cards__help"} onClick={()=>this.setState({explain: true, explainStep: 1})}/>
                { this.state.explain && (
                    <div className={"explain-overlay -step" + this.state.explainStep}>

                        { this.state.explainStep === 1 && (
                            <button className={"explain-overlay__close"} onClick={()=>this.setState({explainStep: 2})}>
                                Weiter
                            </button>
                        )}

                        { this.state.explainStep === 2 && (
                            <button className={"explain-overlay__close"} onClick={()=>this.setState({explain: false})}>
                                Karten jetzt gestalten
                            </button>
                        )}
                    </div>
                )}

                {confirmation}
            </Fragment>
        );
    }
}

export default FormCards;
