import React, {Fragment} from 'react';

import "./FormCode.scss"

class FormCode extends React.Component {

    constructor(props) {
        super(props);
        this.goNext = this.goNext.bind(this);
        this.state = {
            valid: false,
            code: '',
            codeId: undefined,
            haveToCheckEmail: false,
        }
    }


    checkCode(){
        //console.log(this.code.value);

        fetch(window.API + "/codes/check",{
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                code: this.code.value
            })
        })
        .then(response => response.json())
        .then((responseJson)=> {
            console.log(responseJson);

            if (responseJson !== 'nocode' && responseJson !== 'notactive' && responseJson !== 'alreadyValidated'){
                this.setState({
                    valid: true,
                    code: responseJson.code,
                    codeId: responseJson.id,
                    haveToCheckEmail: false,
                })
            } else if (responseJson === 'alreadyValidated'){
                this.setState({
                    valid: false,
                    haveToCheckEmail: true,
                })
            } else{
                this.setState({
                    valid: false,
                    code: '',
                    codeId: undefined,
                    haveToCheckEmail: false,
                })
            }

        })
        .catch(error=>console.log(error));
    }


    goNext(){
        if(this.state.valid){
            this.props.next(this.state.codeId)
        }
    }

    reuseCode(){
        this.props.reuseCode(this.code.value);
    }

    render(){
        return (
            <Fragment>
                <div className={"form-code"}>

                    <div className={"form-code__helper"}>
                        <h1>Erstelle deine <span>individuellen</span> Spielkarten</h1>
                        <p>
                            Gib deinen Gutschein Code ein und erstelle deine personalisierten Spielkarten für Kampf gegen das Bünzlitum. <a href={"https://www.kampfgegendasbuenzlitum.ch/pages/drucktermin"} target={"_blank"}>Info zum nächsten Drucktermin.</a>
                        </p>
                    </div>

                    <div className={"form-code__group"}>
                        <input type={"text"}
                               ref={(c) => this.code = c}
                               className={"form-code__input" + ( this.state.valid ? " form-code__input--valid": "" )}
                               onChange={() => this.checkCode()}
                               placeholder={"Gutschein Code"}
                        />
                        <button className={"form-code__button" + ( this.state.valid ? " form-code__button--valid": "" )} onClick={this.goNext}>Weiter</button>
                    </div>

                    {this.state.valid && <div className={"form-code__valid"}>Code ist Gültig</div>}

                    {this.state.haveToCheckEmail &&
                        <div className={"form-code__haveToCheckEmail"} onClick={() => this.reuseCode()}>
                            Code wurde bereits verwendet. Klicke <span>hier</span> um diesen Code wieder zuverwenden.
                        </div>
                    }


                </div>
                <a href={"mailto:info@kampfgegendasbuenzlitum.ch"} className={"support-link"}>Support: info@kampfgegendasbuenzlitum.ch</a>
        </Fragment>

        );
    }
}

export default FormCode;
