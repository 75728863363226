import React from 'react';

import "./FormMail.scss"

class FormMailReuse extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          email: '',
        }
    }

    render(){
        return (

            <div className={"form-mail"}>

                <div className={"form-mail__helper"}>
                    <h1>Willkommen zurück.</h1>
                    <p>
                      Du kannst deinen Code wiederverwenden. Dazu musst du nur deine E-Mail Adresse die du bei deiner
                      letzten Bestellung verwendet hast angeben.
                    </p>
                </div>

                <input type={"text"}
                       className={"form-mail__input"}
                       placeholder={"E-Mail Adresse"}
                       ref={(m) => this.mail = m}
                       onChange={() => (this.state.email = this.mail.value)}
                />

            </div>

        );
    }
}

export default FormMailReuse;
