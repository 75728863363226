import React from 'react';

import "./Header.scss"

import logo from "../img/logo.svg";
import logoAddon from "../img/logo__addon.svg";

class Header extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            animationRunning: false,
            hover: false,
        }
    }


    toggleHeader(){
        this.props.toggleHeaderState();
        this.setState({
            open: !this.state.open,
            animationRunning: true,
        });
        setTimeout(
            () => this.state.animationRunning = false,
            300
        )

    }

    onHover(){
        if(!this.state.hover && !this.state.open && !this.state.animationRunning){
            this.setState({
                hover: true,
            })
        }
    }
    onLeave(){
        this.setState({
            hover: false,
        })
    }

    render(){
        return (
            <header className={this.state.open ? "header--open" : ""}>

                <div className={"header__background" + (this.state.hover && !this.state.animationRunning ? " header__background--hover": "")}></div>

                <div className={"header__logo"}
                     onClick={() => this.toggleHeader()}
                     // onMouseOver={() => this.onHover()}
                     // onMouseLeave={() => this.onLeave()}
                >
                    <img src={logo} alt={"Logo - Kampf gegen das Bünzlitum"}/>
                </div>

                <div className={"header__logoAddon"}>
                    <img src={logoAddon} alt={"Logo - Kampf gegen das Bünzlitum"}/>
                </div>


                <nav className={"header__menu"}>
                    <a href={"https://www.kampfgegendasbuenzlitum.ch"}>Shop</a>
                    <a href={"mailto:info@kampfgegendasbuenzlitum.ch"}>Kontakt</a>
                    <a style={{cursor: "pointer"}} onClick={() => this.toggleHeader()}>Schliessen</a>
                </nav>

            </header>
        );
    }
}

export default Header;
