import React from 'react';

import "./Card.scss"

import logoCards from "../img/logo_cards.svg"

class Card extends React.Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
          value: this.props.value || "",
        }
    }

    handleChange(event){
      this.setState({value: event.target.value});
      this.props.onChange(event.target.value);
    }

    render(){
        return (

            <div className={"card"}>

                <textarea className={"card__input"}
                          onChange={this.handleChange}
                          placeholder={"Erstelle einen eigenen Text."}
                          maxLength={80}
                          value={this.state.value}>

                </textarea>

                <div className={"card__logo"}>
                    <img src={logoCards}/>
                </div>

                <div className={"card__name"}>
                    <input value={this.props.name}
                           placeholder={"Kartentitel"}
                           onChange={(event) => this.props.handleNameChange(event.target.value)}/>
                </div>

            </div>

        );
    }
}

export default Card;
