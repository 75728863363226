import React from 'react';

import "./Intro.scss";

class Intro extends React.Component {

    constructor(props) {
        super(props);
        this.phrases = [
            "In der Bibel steht: <span></span>.",
            "<span></span> wird den 3. Weltkrieg auslösen.",
            "Der Lehrplan 21 beinhaltet neu <span></span>.",
            "Letzte Nacht habe ich von <span></span> geträumt.",
            "Lieber Dr. Sommer, bitte erklär mir <span></span>.",
            "Letzte Woche rollte ein Puch Maxi über <span></span>.",
            "Göläs neustes Album heisst: <span></span>.",
            "Mit <span></span> ist alles besser.",
            "Chasperlis neuer Erzfeind ist <span></span>.",
            "Zu meinem Bierchen mag ich <span></span>.",
            "Erstelle deine <strong>individuellen</strong> Spielkarten.",
        ];

        this.state = {
            phraseIdx: 0,
            speed: 100,
            finished: false,
        };
    }

    componentDidMount() {
        this.setInterval();
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    setInterval(){
        this.timerID = setInterval(
            () => this.changeThrough(),
            this.state.speed
        );
    }

    changeThrough() {
        if(this.state.phraseIdx < 10){
            clearInterval(this.timerID);
            this.setState({
                phraseIdx: this.state.phraseIdx + 1,
                speed: this.state.speed + 40,
            });
            this.setInterval();
        } else{

            clearInterval(this.timerID);

            setTimeout(
                () => this.setState({finished: true}),
                1000,
            );
        }
    }


    render(){
        return (
            <div className={"intro" + ( this.state.finished ? " intro--closed" : " intro--open" )}>
                <div className={"intro__background"}></div>

                <div className={"intro__phrase"} dangerouslySetInnerHTML={{ __html: this.phrases[this.state.phraseIdx] }}>

                </div>
            </div>
        );
    }
}

export default Intro;
