import React from 'react';

import "./PageConfirmation.scss"

class PageConfirmation extends React.Component {



    render(){
        return (

            <div className={"confirmation"}>

                <div className={"confirmation__text"}>
                    <h1>Danke für deine Bestellung!</h1>
                    <p>
                        Deine Kartensets machen sich nun auf den Weg in die Druckerei. <a href={"https://www.kampfgegendasbuenzlitum.ch/pages/drucktermin"} target={"_blank"}>Hier</a> wirst du informiert, wann der nächste Druck stattfindet.
                    </p>
                    <p>
                        Bei Fragen wende dich an <a href={"mailto:info@kampfgegendasbuenzlitum.ch"}>info@kampfgegendasbuenzlitum.ch</a> mit Vermerk auf deine Bestellnummer #{this.props.orderId}.
                    </p>
                </div>

            </div>

        );
    }
}

export default PageConfirmation;
