import React from 'react';

import "./Footer.scss"



const stepNames = [
    "",
    "Dein Kartencode einlösen.",
    "",
    "E-Mail Adresse.",
    "Kontaktdaten angeben.",
    "Zahlung.",
    "Bestätigung."
];

class Footer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            confirmationOpen: false,
        }
    }

    goNext(){
        // if (this.props.formState === 2) {
        //     this.setState({confirmationOpen: true})
        // } else {
            this.props.next();
        // }
    }

    confirm(){
        this.setState({confirmationOpen: false});
        this.props.next();
    }

    decline(){
        this.setState({confirmationOpen: false});
    }

    render(){

        const next = this.props.formState < 5 || this.props.formState === 99 ? (<button onClick={() => this.goNext()}>weiter</button>) : "";

        const confirmation = this.state.confirmationOpen ? (
            <div className={"confirmation__popup"}>

                <div className={"confirmation__message"}>
                    Hast du alle Karten nach deinen Wünschen erstellt?
                </div>

                <div className={"buttons"}>
                    <button onClick={() => this.decline()}>überarbeiten</button>
                    <button onClick={() => this.confirm()}>Ja</button>
                </div>
            </div>

        ) : "";

        const prev = this.props.formState === 3 ? (<button onClick={() => this.props.prev()}>zurück</button> ) : '';

        return (
            <React.Fragment>
                <footer>
                    <div className={"footer__inner"}>
                        <div className={"footer__inner__left"}>
                            {prev || stepNames[this.props.formState]}
                        </div>
                        <div className={"footer__inner__right"}>
                            {next}
                        </div>
                    </div>
                </footer>
                {confirmation}
            </React.Fragment>
        );
    }
}

export default Footer;
