import React from 'react';
import {CardNumberElement, CardExpiryElement, CardCVCElement, injectStripe} from 'react-stripe-elements';


import "./FormPayment.scss"
import Error from "../components/Error";

class FormPayment extends React.Component {

    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);
        this.state = {
            error: undefined,
            loading: false,
        }
    }



    async submit(ev) {

        this.setState({
            error: '',
            loading: true,
        });
        //this.props.next();

        // User clicked submit
        let {token} = await this.props.stripe.createToken({name: this.props.order.vorname + ' ' + this.props.order.name });

        if (typeof token === "undefined"){
            this.setState({
                error: 'Zahlung fehlgeschlagen, bitte erneut probieren.',
                loading: false,
            });
        } else {
            fetch(window.API + "/payment/charge",{
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: token.id,
                    orderId: this.props.orderId,
                    email: this.props.email,
                })
            })
                .then(response => response.json())
                .then((responseJson)=> {
                    console.log(responseJson);
                    this.props.next();
                })
                .catch((error)=>{
                    console.log(error);
                    this.setState({
                        error: error,
                        loading: false,
                    });
                });
        }
    }


    render(){

        const createOptions = (fontSize, padding) => {
            return {
                style: {
                    base: {
                        fontSize: "20px",
                        background: "white",
                        color: '#424770',
                        letterSpacing: '0.025em',

                        '::placeholder': {
                            color: '#aab7c4',
                        },
                        padding,
                    },
                    invalid: {
                        color: '#9e2146',
                    },
                },
            };
        };

        const handleBlur = () => {
            //console.log('[blur]');
        };
        const handleChange = (change) => {
            //console.log('[change]', change);
        };
        const handleClick = () => {
            //console.log('[click]');
        };
        const handleFocus = () => {
            //console.log('[focus]');
        };
        const handleReady = () => {
            //console.log('[ready]');
        };

        const loading = this.state.loading ? (<div className={"payment__loading"}>Zahlung wird ausgeführt...</div>) : "";

        return (
            <React.Fragment>

            <div className={"form-payment"}>

                <div className={"form-payment__title"}>
                    <h1>Zahlung erforderlich.</h1>
                    <p>
                        Du hast zusätzliche Kartensets bestellt für CHF {parseFloat(this.props.order.price).toFixed(2)} bestellt.
                        Bezahle bitte deine Bestellung mit der Kreditkarte.
                    </p>
                </div>

                <div className={"form-payment__inner"}>

                    <div className={"form-payment__inner_full"}>
                        <label>
                            Kartennummer
                            <CardNumberElement
                                onBlur={handleBlur}
                                onChange={handleChange}
                                onFocus={handleFocus}
                                onReady={handleReady}
                                {...createOptions(this.props.fontSize)}
                            />
                        </label>
                    </div>

                    <div className={"form-payment__inner_half"}>
                        <label>
                            Ablaufdatum
                            <CardExpiryElement
                                onBlur={handleBlur}
                                onChange={handleChange}
                                onFocus={handleFocus}
                                onReady={handleReady}
                                {...createOptions(this.props.fontSize)}
                            />
                        </label>
                    </div>

                    <div className={"form-payment__inner_half"}>
                        <label>
                            CVC
                            <CardCVCElement
                                onBlur={handleBlur}
                                onChange={handleChange}
                                onFocus={handleFocus}
                                onReady={handleReady}
                                {...createOptions(this.props.fontSize)}
                            />
                        </label>
                    </div>


                </div>

            </div>
                <button className={"form-payment__inner_full__pay"} onClick={this.submit}>Zahlung ausführen</button>
                <Error message={this.state.error}/>
                {loading}
            </React.Fragment>

        );
    }
}

export default injectStripe(FormPayment);
