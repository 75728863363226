import React from 'react';

import "./FormContact.scss"

class FormContact extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            name: "",
            surname: "",
            street: "",
            number: "",
            plz: "",
            place: "",
        }
    }


    render(){
        return (

            <div className={"form-contact"}>

                <div className={"form-contact__title"}>
                    Und wohin sollen wir deine neuen Karten senden?
                </div>

                <div className={"form-contact__inner"}>

                    <div className={"form-contact__inner_half"}>
                        <label>
                            Vorname
                            <input autoComplete={"off"} name={"surname"} type={"text"} onChange={val => this.setState({surname: val.target.value})}/>
                        </label>
                    </div>

                    <div className={"form-contact__inner_half"}>
                        <label>
                            Name
                            <input autoComplete={"off"} name={"name"} type={"text"} onChange={val => this.setState({name: val.target.value})}/>
                        </label>
                    </div>

                    <div className={"form-contact__inner_big"}>
                        <label>
                            Strasse
                            <input autoComplete={"off"} name={"street"} type={"text"} onChange={val => this.setState({street: val.target.value})}/>
                        </label>
                    </div>

                    <div className={"form-contact__inner_small"}>
                        <label>
                            Hausnummer
                            <input autoComplete={"off"} name={"number"} maxLength={5} type={"text"} onChange={val => this.setState({number: val.target.value})}/>
                        </label>
                    </div>

                    <div className={"form-contact__inner_small"}>
                        <label>
                            PLZ
                            <input autoComplete={"off"} name={"plz"} max={9999} type={"number"} onChange={val => this.setState({plz: val.target.value})}/>
                        </label>
                    </div>

                    <div className={"form-contact__inner_big"}>
                        <label>
                            Ort
                            <input autoComplete={"off"} name={"place"} type={"text"} onChange={val => this.setState({place: val.target.value})}/>
                        </label>
                    </div>

                </div>

            </div>

        );
    }
}

export default FormContact;
