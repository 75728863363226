import React from 'react';

import "./Error.scss"

class Error extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
        }
    }



    render(){

        return this.props.message ? (
            <div className={"error"}>{this.props.message}</div>
        ) : "";
    }
}

export default Error;
