import React from 'react';

import './App.scss';
import Header from "./components/Header";
import Intro from "./components/Intro";
import FormCode from "./pages/FormCode";
import Footer from "./components/Footer";
import FormCards from "./pages/FormCards";
import FormContact from "./pages/FormContact";
import FormMail from "./pages/FormMail";
import FormPayment from "./pages/FormPayment";
import PageConfirmation from "./pages/PageConfirmation";

import {Elements, StripeProvider} from 'react-stripe-elements';
import Error from "./components/Error";
import FormMailReuse from "./pages/FormMailReuse";

// window.onbeforeunload = function() {
//     return "Did you save your stuff?"
// };

function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

// window.API = 'http://192.168.0.192:8000/api/v1';
// window.API = 'http://localhost:8000/api/v1';
// window.API = 'https://buenzliapi.colinluond.ch/api/v1';
window.API = 'https://api.mybuenzli.ch/api/v1';
// window.StripeKey = 'pk_test_sMeQObcHTZdNIWyiJi3a7wcj';
window.StripeKey = 'pk_live_FUAsUjNSpyDfvN7Hz42dYt9a';

class App extends React.Component{


    constructor(props) {
        super(props);

        this.cards = React.createRef();
        this.mail = React.createRef();
        this.mailReuse = React.createRef();
        this.contact = React.createRef();

        this.state = {
            intro: true,
            headerOpen: false,
            formState: 1,
            footer: false,
            code: '',
            valid: false,
            cards: '',
            cardsName: '',
            email: '',
            orderId: undefined,
            codeId: undefined,
            order: undefined,
            error: '',
            reuseCode: false,
            confirmation: false,
            confirmationText: "",
            confirmationGoThrough: false,
        }
    }

    toggleHeaderState(){
        this.setState({
            headerOpen: !this.state.headerOpen,
        })
    }


    createOrder(){
        console.log(this.state.cards + ' ' + this.state.email);

        fetch(window.API + "/order/create",{
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                codeId: this.state.codeId,
                email: this.state.email,
                cards: JSON.stringify(this.state.cards),
                cardsTitle: this.state.cardsName,
                reuse: this.state.reuseCode,
            })
        })
            .then(response => response.json())
            .then((responseJson)=> {
                console.log(responseJson);
                this.setState({orderId: responseJson.id, order: responseJson, formState: this.state.formState + 1})
            })
            .catch((error)=>{
                console.log(error);
                this.setState({
                    formState: 1,
                    error: "Fehler"
                })
            });
    }

    updateOrderWithContact(){
        fetch(window.API + "/order/updateContact",{
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                orderId: this.state.orderId,
                contact: JSON.stringify(this.contact.current.state),
            })
        })
            .then(response => response.json())
            .then((responseJson)=> {
                console.log(responseJson);

                let next = responseJson.price > 0 ? this.state.formState + 1 : this.state.formState + 2;
                this.setState({order: responseJson, formState: next})
            })
            .catch((error)=>{
                console.log(error);
                this.setState({
                    formState: 1,
                    error: error,
                })
            });
    }

    nextStep(){

        if(this.validateForm()){

            if (this.state.formState === 2){
                this.state.cards = this.cards.current.state.cards;
                this.state.cardsName = this.cards.current.state.name;

                if(this.state.reuseCode){
                    this.state.formState = this.state.formState + 1;
                    this.createOrder();
                } else{
                    this.setState({
                        formState: this.state.formState + 1,
                    })
                }
            }

            else if (this.state.formState === 3){
                this.state.email = this.mail.current.state.email;
                this.createOrder();
            } else if(this.state.formState === 4){
                this.updateOrderWithContact();
            }  else if(this.state.formState === 99){
                this.checkReusableCode();
            } else{
                if(this.state.formState < 6){
                    this.setState({
                        formState: this.state.formState + 1,
                    })
                }
            }

        }
    }

    prevStep(){
        this.setState({
            formState: this.state.formState - 1,
            error: "",
        })
    }


    validateForm(){
        this.setState({error: undefined});
        switch (this.state.formState) {

            case 2:

                if(this.state.confirmationGoThrough){
                    this.state.confirmation = false;
                    this.state.confirmationGoThrough = false;
                    return true;
                }

                let ok = true;
                this.cards.current.state.cards.forEach((set) => {
                    set.forEach((card) => {
                        if (!card.value){
                            ok = false;
                        }
                    });
                });
                if(!this.cards.current.state.name){
                    this.setState({
                        confirmation: true,
                        confirmationText: "Du hast keinen Kartentitel angegeben. Möchtest du wirklich fortfahren?",
                    });
                    return false
                }

                if(ok){
                    this.setState({
                        confirmation: true,
                        confirmationText: "Hast du alle Karten nach deinen Wünschen erstellt?",
                    });
                    return false;
                } else{
                    this.setState({
                        confirmation: true,
                        confirmationText: "Einige Karten haben keinen Text. Möchtest du wirklich fortfahren?",
                    });
                    return false;
                }

            case 3: //mail
                if (validateEmail(this.mail.current.state.email)){
                    return true;
                } else {
                    this.setState({error: 'Bitte gib eine gültige E-Mail Adresse ein.'});
                    return false;
                }
            case 4: //contact
                if (
                    this.contact.current.state.name &&
                    this.contact.current.state.surname &&
                    this.contact.current.state.street &&
                    this.contact.current.state.number &&
                    this.contact.current.state.plz &&
                    this.contact.current.state.place
                ){
                    return true;
                } else {
                    this.setState({error: 'Wir brauchen deine komplette Adresse um die Karten zu versenden.'});
                    return false;
                }
            case 99:
                if (validateEmail(this.mailReuse.current.state.email)){
                    return true;
                } else {
                    this.setState({error: 'Bitte gib eine gültige E-Mail Adresse ein.'});
                    return false;
                }
            default:
                return true;

        }
    }

    handleCodeReuse(code){
        // console.log(code);

        this.setState({
            code: code,
            formState: 99,
            valid: true,
        })
    }

    checkReusableCode(){
        fetch(window.API + "/codes/checkReusable",{
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                code: this.state.code,
                email: this.mailReuse.current.state.email,
            })
        })
            .then(response => response.json())
            .then((responseJson)=> {
                if (responseJson !== "error"){
                    this.setState({
                        code: responseJson.code,
                        codeId: responseJson.id,
                        email: responseJson.validated_by,
                        formState: 2,
                        reuseCode: true,
                    });
                } else {
                    this.restart("Wiederverwenung nicht erlaubt. Bitte wende dich an den Support.");
                }
            })
    }

    restart(error){
        this.setState({
            intro: !error,
            headerOpen: false,
            formState: 1,
            footer: false,
            code: '',
            valid: false,
            cards: '',
            email: '',
            orderId: undefined,
            codeId: undefined,
            order: undefined,
            error: error || '',
        });

        if(error){
            setTimeout(() => this.setState({error: ""}), 5000);
        }
    }

    render() {

        let form = "";

        const state = this.state.valid ? this.state.formState : 1;

        switch (state) {
            case 1:
                form = (<FormCode next={(codeId) => {this.setState({codeId: codeId, valid: true}); this.nextStep()}} reuseCode={(code) => this.handleCodeReuse(code)}/>);
                break;
            case 2:
                form = (<FormCards ref={this.cards} next={() => this.nextStep()} reUse={this.state.reuseCode}/>);
                break;
            case 3:
                form = (<FormMail ref={this.mail}/>);
                break;
            case 4:
                form = (<FormContact ref={this.contact} orderId={this.state.orderId}/>);
                break;
            case 5:
                form = (
                    <StripeProvider apiKey={window.StripeKey}>
                        <Elements>
                            <FormPayment
                                code={this.state.code}
                                email={this.state.email}
                                orderId={this.state.orderId}
                                order={this.state.order}
                                next={() => this.nextStep()}
                            />
                        </Elements>
                    </StripeProvider>
                );
                break;
            case 6:
                form = (<PageConfirmation orderId={this.state.orderId}/>);
                break;
            case 99:
                form = (<FormMailReuse ref={this.mailReuse} code={this.state.code} next={() => {}} restart={() => this.restart()}/>);
                break;
            default:
                form = ("404 Page not found");
                break;
        }

        const footer = this.state.formState > 1 && this.state.formState !== 6  ? (
            <Footer
                formState={this.state.formState}
                open={this.state.footer}
                next={() => this.nextStep()}
                prev={() => this.prevStep()}
            />
        ) : "";

        const intro = this.state.formState === 1 && this.state.intro ? (<Intro/>) : "";
        const header = this.state.formState === 1 || this.state.formState === 6 ? (<Header toggleHeaderState={() => this.toggleHeaderState()}/>) : "";

        const confirmation = this.state.confirmation ? (
            <div className={"confirmation__popup"}>

                <div className={"confirmation__message"}>
                    {this.state.confirmationText}
                </div>

                <div className={"buttons"}>
                    <button onClick={() => this.confirmNo()}>überarbeiten</button>
                    <button onClick={() => this.confirmOk()}>Ja</button>
                </div>
            </div>

        ) : "";

        return (
            <div className="App">
                {intro}

                {header}

                {form}

                {footer}

                <Error message={this.state.error}/>

                {confirmation}

            </div>
        );
    }

    confirmNo(){
        this.setState({confirmation: false});
    }

    confirmOk(){
        this.state.confirmationGoThrough = true;
        this.nextStep();
    }


}

export default App;

